import React from 'react';
import { Link } from 'gatsby';

import { Routes } from '@constants/routes';

export default ({ headline, subtitle, assets }) => {
  const banner = assets.filter(asset => asset.title === 'serviceupBanner')[0];
  const serviceUpLogo = assets.filter(asset => asset.title === 'logoServiceUp')[0];

  return (
    <section className="w-100" style={{ backgroundImage: `url(${banner.file.url})`, backgroundSize: 'cover' }}>
      <div className="container pb-5 pt-5">
        <div className="row m-5">
          <div className="col-lg-6">
            <img
              src={serviceUpLogo.file.url}
              alt="serviceup logo"
            />
            <h1 className="h3 font-weight-bold mt-5">{headline}</h1>
            <h2 className="h6 text-dark pt-3">{subtitle}</h2>
            <div className="d-flex mt-4">
              <a href={`/service-up#pricing`} className="btn btn-outline-dark mr-4">See All Plans</a>
              <Link
                to={Routes.ContactUs}
                className="btn btn-sm btn-su"
              >Register Interest</Link>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

